import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const [searchParams] = useSearchParams();
  const [info, setInfo] = useState({
    status: null,
    ticketCode: null,
    ticketType: null,
    remainCount: 0,
    appliedCount: 0,
  });
  const [showPopup, setShowPopup] = useState();

  // 회원번호
  const memberNo = searchParams.get('memberNo');
  // 10030000007730544 : 김련호
  // 10030000007730565 : 이동화

  useMountEffect(() => {
    // 회원번호 없는 경우
    if (memberNo === null || memberNo === '') return;

    axios
      .get(`${HOST_URL}/v1/promo/trot-concert?memberNo=${memberNo}`)
      .then(({ data }) => data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          setInfo({ ...info, ...data });
        }
      });
  });

  const doApply = () => {
    // 이미 당첨된 경우
    if (info.ticketCode) {
      setShowPopup('GIFT');
      return;
    }

    alert('이벤트가 종료되었습니다.');
  };

  const copyToClipboard = () => {
    const element = document.createElement('textarea');
    element.value = info.ticketCode;
    document.body.appendChild(element);

    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);

    alert('교환번호가 복사되었습니다.');
  };

  const closePopup = () => {
    setShowPopup();
  };

  const moveToOrder = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'NAVIGATE',
        screenName: 'PickupShopList',
      }),
    );
  };

  const moveToExternal = (url) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'EXTERNAL',
        url,
      }),
    );
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/trot/01.jpg')} alt="" />
      <img src={require('../../assets/images/trot/02.jpg')} alt="" />
      <div className={cx('background_01')}>
        <img src={require('../../assets/images/trot/button-open.jpg')} alt="" onClick={doApply} />
      </div>
      <div className={cx('background_01')}>
        <div className={cx('row')} style={{ marginTop: 24 }}>
          <div className={cx('label')}>현재 응모 가능 횟수</div>
          <div className={cx('value')}>{info.remainCount}회</div>
        </div>
        <div className={cx('row')} style={{ marginTop: 16 }}>
          <div className={cx('label')}>응모 완료 횟수</div>
          <div className={cx('value')}>{info.appliedCount}회</div>
        </div>
      </div>
      <img src={require('../../assets/images/trot/04.jpg')} alt="" />
      <img src={require('../../assets/images/trot/05.jpg')} alt="" />
      <div className={cx('background_02')} style={{ paddingBottom: 16 }}>
        <img
          src={require('../../assets/images/trot/button-concert.jpg')}
          alt=""
          onClick={() => moveToExternal('https://programs.sbs.co.kr/fil/2024sbstrot/basicinfo/84173')}
        />
      </div>
      <img src={require('../../assets/images/trot/07.jpg')} alt="" />
      <img src={require('../../assets/images/trot/08.jpg')} alt="" />
      <img src={require('../../assets/images/trot/09.jpg')} alt="" />

      {['CHECK', 'NEXT'].includes(showPopup) && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            {showPopup === 'CHECK' && <img src={require('../../assets/images/trot/popup-check.jpg')} alt="" />}
            {showPopup === 'NEXT' && <img src={require('../../assets/images/trot/popup-next.jpg')} alt="" />}
            <div className={cx('bottom')}>
              <img
                src={require('../../assets/images/trot/button-order.jpg')}
                alt=""
                onClick={moveToOrder}
                style={{ width: 510 / 2.2 }}
              />
            </div>
          </div>
        </div>
      )}
      {['GIFT'].includes(showPopup) && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            {info.ticketType === 0 && <img src={require('../../assets/images/trot/popup-gift-0.jpg')} alt="" />}
            {info.ticketType === 1 && <img src={require('../../assets/images/trot/popup-gift-1.jpg')} alt="" />}
            {info.ticketType === 2 && <img src={require('../../assets/images/trot/popup-gift-2.jpg')} alt="" />}
            <div className={cx('fieldWrapper')}>
              <div className={cx('field')}>
                <div className={cx('code')}>{info.ticketCode}</div>
                <div className={cx('button')} onClick={copyToClipboard}>
                  복사
                </div>
              </div>
            </div>
            <img src={require('../../assets/images/trot/popup-gift-guide.jpg')} alt="" />
            <div className={cx('bottom')}>
              <img
                src={require('../../assets/images/trot/button-android.jpg')}
                alt=""
                onClick={() => moveToExternal('http://bit.ly/37P8uUu')}
                style={{ width: 246 / 2.2 }}
              />
              <img
                src={require('../../assets/images/trot/button-ios.jpg')}
                alt=""
                onClick={() => moveToExternal('http://apple.co/3aRndjE')}
                style={{ width: 248 / 2.2 }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
